import('./bootstrap')
import React, { Suspense } from 'react'
import { render } from 'react-dom'
import { createInertiaApp } from '@inertiajs/inertia-react'
import { InertiaProgress } from '@inertiajs/progress'
// import LayoutWrapper from './Pages/Saas/@core/layouts/components/layout-wrapper'
import VerticalLayout from './Pages/Saas/layouts/VerticalLayout'
import Layout from './Pages/Saas/layouts/Layout'
// import VerticalLayout from '@layouts/VerticalLayout'
import { Provider } from "react-redux"
import { store } from './Pages/Saas/redux/store'


// ** React Imports
import { BrowserRouter } from 'react-router-dom'
import themeConfig from './Pages/Saas/configs/themeConfig'

// ** Intl, CASL & ThemeColors Context
import ability from './Pages/Saas/configs/acl/ability'
import { AbilityContext } from './Pages/Saas/utility/context/Can'
import { ThemeContext } from './Pages/Saas/utility/context/ThemeColors'
import { Toaster } from 'react-hot-toast'

// ** i18n
import './Pages/Saas/configs/i18n'

// ** Spinner (Splash Screen)
import Spinner from './Pages/Saas/@core/components/spinner/Fallback-spinner'

// ** Ripple Button
import './Pages/Saas/@core/components/ripple-button'

// ** Fake Database
import './Pages/Saas/@fake-db'

// ** PrismJS
import 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'
import 'prismjs/components/prism-jsx.min'

// ** React Perfect Scrollbar
import 'react-perfect-scrollbar/dist/css/styles.css'

// ** React Hot Toast Styles
import '@styles/react/libs/react-hot-toasts/react-hot-toasts.scss'

import '../css/app.css'

import './Pages/Saas/@core/assets/fonts/feather/iconfont.css'
import './Pages/Saas/@core/scss/core.scss'
import './Pages/Saas/assets/scss/style.scss'
import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css';

const appName = window.document.getElementsByTagName('title')[0]?.innerText || 'Laravel'

createInertiaApp({
    title: (title) => `${title} - ${appName}`,
    resolve: async (name, a, b) => {

        let path = name;
        const pages = import.meta.glob('./Pages/**/*.jsx')
        const splitName = name.split('/')
        const scriptName = splitName[splitName.length - 1];
        
        if (scriptName === "") {
            splitName[splitName.length - 1] = 'index';
            path = splitName.join('/')
        } 
        

        const page = (await pages[`./Pages/${path}.jsx`]()).default
        const withoutLayouts = ["Landing", "Login", "Register", "VerifyEmail"]
        
        if (withoutLayouts.includes(scriptName)) return page;
        page.layout = page.layout || Layout
        return page

    },
    setup({ el, App, props }) {
        render(React.createElement(BrowserRouter, {
            children:React.createElement(Provider, {
                store,
                children: React.createElement(Suspense, {
                    fallback:React.createElement(Spinner),
                    children:React.createElement(AbilityContext.Provider, {
                        value:ability,
                        children:React.createElement(ThemeContext, {
                            children:[
                                React.createElement(App, {...props, key:1}),
                                React.createElement(Toaster, {
                                position:themeConfig.layout.toastPosition,
                                toastOptions:{ className: 'react-hot-toast' },
                                key:2
                            })
                        ]
                        })
                    })
                    
                })
            })
        }), el)
    }
})
InertiaProgress.init({ color: `rgb(75, 132, 255)`})