import React, { Fragment, useEffect, memo } from 'react'
import { Mail, MessageSquare, CheckSquare, Calendar, FileText, Circle, Home, File, Folder, Briefcase, FolderPlus, DollarSign, BookOpen, AlignLeft } from 'react-feather'

// ** React Imports
import { Outlet } from 'react-router-dom'
import PropTypes from "prop-types";

// ** Core Layout Import
// !Do not remove the Layout import
import Layout from '../@core/layouts/VerticalLayout'
import classnames from 'classnames'

// ** Menu Items Array
import { useSelector, useDispatch } from 'react-redux'
import { usePage } from '@inertiajs/inertia-react'

const VerticalLayout = ({ children }) => {

  const store = useSelector(state => state)

  const { auth: { user, projects } } = usePage().props;

  const { company } = user;
  
  const navbarStore = store.navbar
  const contentWidth = store.layout.contentWidth
  const transition = store.layout.routerTransition
  const layoutStored = store.layout.layout

  //** Vars
  const appLayoutCondition =
    (layoutStored.layout === 'horizontal' && false) ||
    (layoutStored.layout === 'horizontal' && false)
  const Tag = appLayoutCondition ? 'div' : Fragment

  const navigationMenuData = [
    {
      id: 'dashboard',
      title: 'Dashboard',
      icon: <Home size={20} />,
      navLink: '/home',
    },
  ];

  const adminData = [
    {
      header: 'CRM'
    },
    {
      id: 'companies',
      title: 'Companies',
      icon: <BookOpen size={20} />,
      navLink: route('companies.index'),
    },
    {
      id: 'projects',
      title: 'Projects',
      icon: <DollarSign size={20} />,
      navLink: route('projects.index'),
      route: 'projects.index'
    },
    {
      header: 'Backoffice'
    },
    {
      id: 'forms',
      title: 'Forms',
      icon: <AlignLeft size={20} />,
      navLink: route('forms.index'),
    },
  ]

  const resourcesMenuData = [

    {
      header: company?.type ?? "",
    },
  ]
  const promoterMenuData = [

    {
      id: 'projects-category',
      title: 'Projects',
      icon: <Briefcase size={20} />,
      children: [
        {
          id: 'new-project',
          title: 'New project',
          icon: <FolderPlus size={20} />,
          navLink: route('promoter.projects.create'),
        },
        {
          id: 'my-projects',
          title: 'My projects',
          icon: <Folder size={20} />,
          navLink: route('promoter.projects.index'),
          badge: 'light-warning',
          badgeText: projects,
        },
      ]
    },
  ]
  const investorMenuData = [

    {
      id: 'vehicles-category',
      title: 'Vehicles',
      icon: <Briefcase size={20} />,
      children: [
        {
          id: 'new-vehicle',
          title: 'New vehicle',
          icon: <FolderPlus size={20} />,
          navLink: route('investor.projects.create'),
        },
        {
          id: 'my-vehicles',
          title: 'My vehicles',
          icon: <Folder size={20} />,
          navLink: route('investor.projects.index'),
          badge: 'light-warning',
          badgeText: projects,
        },
      ]
    },
  ]

  const menuData = []

  if (projects > 0 || user?.role === "Admin") menuData.push(...navigationMenuData)

  if (user?.role === "Admin") menuData.push(...adminData)

  if (projects > 0) menuData.push(...resourcesMenuData)

  if (company?.type === "Investor") menuData.push(...investorMenuData)
  else if (company?.type === "Promoter") menuData.push(...promoterMenuData)


  return (
    <Layout menuData={menuData}>
      <div
        className={classnames('app-content content overflow-hidden', {
          ['']: '',
          'show-overlay': navbarStore.query.length
        })}
      >
        <div className='content-overlay'></div>
        <div className='header-navbar-shadow' />
        <div
          className={classnames({
            'content-wrapper': false,
            'content-area-wrapper': false,
            'container-xxl p-0': contentWidth === 'boxed',
            [`animate__animated animate__${transition}`]: transition !== 'none' && transition.length
          })}
        >
          <Tag {...(appLayoutCondition ? { className: 'content-body' } : {})}>{children}</Tag>
        </div>
      </div>
    </Layout>
  )
}

export default VerticalLayout


VerticalLayout.propTypes = {
  children: PropTypes.object
}