import VerticalLayout from "./VerticalLayout";

import React from 'react'

function Layout(children) {
    return (
        <VerticalLayout>{children}</VerticalLayout>
    )
}

export default Layout